@font-face {
  font-family: OpenSans-SemiBold;
  src: url(../assets/fonts/SanFrancisco-Bold.otf);
  font-weight: 600;
}

@font-face {
  font-family: OpenSans-Regular;
  src: url("../assets/fonts/SanFrancisco-Regular.otf");
  font-weight: 400;
}
@font-face {
  font-family: OpenSans-Light;
  src: url("../assets/fonts/SanFrancisco-Light.otf");
  font-weight: 300;
}
@font-face {
  font-family: OpenSans-ExtraLight;
  src: url("../assets/fonts/SanFrancisco-Extrabold.otf");
  font-weight: 200;
}
@font-face {
  font-family: OpenSans-Bold;
  src: url("../assets/fonts/SanFrancisco-Bold.otf");
  font-weight: 700;
}
@font-face {
  font-family: OpenSans-Black;
  src: url("../assets/fonts/SanFrancisco-Black.otf");
  font-weight: 900;
}
@font-face {
  font-family: OpenSans-Medium;
  src: url("../assets/fonts/SanFrancisco-Medium.otf");
  font-weight: 500;
}

* {
  box-sizing: border-box;
}

body {
  font-family: OpenSans-Regular;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

select {
  appearance: none;
  background-image: url("../assets/arrow.svg");
  background-repeat: no-repeat;
  background-position: right 2px center;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-pagination-bullet-active {
  background-color: #fff !important;
}

.custom-next,
.custom-prev {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  background-color: #ffffff;
  border-radius: 50%;
}

.slider-award-image-first {
  background-image: url("../assets/slider-award-first.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.slider-award-image-second {
  background-image: url("../assets/slider-award-second.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.slider-award-image-third {
  background-image: url("../assets/slider-award-third.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.mobile-swiper .swiper-pagination {
  bottom: -5px !important;
}

.mobile-swiper .swiper-pagination-bullet {
  background-color: #e2e2e2;
  height: 6px;
  width: 6px;
}
.mobile-swiper .swiper-pagination-bullet-active {
  background-color: #000 !important;
}

.app-color {
  color: red;
}

.linkColor {
  color: blue;
}

.linear-color {
  background: linear-gradient(114.29deg, #40cc72 4.86%, #5b68f6 90.87%);
}

.hide-scroll {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.hide-scroll::-webkit-scrollbar {
  display: none;
}

.title-text {
  font-weight: bold;
  font-size: 18px;
 
}

.description-text {
  display: inline-block;
  margin-top: 8px;
}